import React from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import Inicio from '../Page/Inicio';
import Editarperfil from '../Page/Editarperfil';
import Editarperfil2 from '../Page/Editarperfil2';
import Editarperfil3 from '../Page/Editarperfil3';
import Editarperfil4 from '../Page/Editarperfil4';
import Editarperfil5 from '../Page/Editarperfil5';
import Editarperfil6 from '../Page/Editarperfil6';
import Agregarusuario from '../Page/Agregarusuario';
import Agregarusuario2 from '../Page/Agregarusuario2';
import Agregarusuario3 from '../Page/Agregarusuario3';
import Agregarusuario4 from '../Page/Agregarusuario4';
import Agregarusuario5 from '../Page/Agregarusuario5';
import Agregarusuario6 from '../Page/Agregarusuario6';
import Agregarusuario7 from '../Page/Agregarusuario7';
import Agregarusuario8 from '../Page/Agregarusuario8';
import Afiliacionorganizaciones from '../Page/Afiliacionorganizaciones';
import Afiliacionorganizaciones2 from '../Page/Afiliacionorganizaciones2';
import Afiliacionorganizaciones3 from '../Page/Afiliacionorganizaciones3';
import Afiliacionorganizaciones4 from '../Page/Afiliacionorganizaciones4';
import Afiliacionorganizaciones5 from '../Page/Afiliacionorganizaciones5';
import Registroconvenios from '../Page/Registroconvenios';
import Registroconvenios2 from '../Page/Registroconvenios2';
import Registroconvenios3 from '../Page/Registroconvenios3';
import Registroconvenios4 from '../Page/Registroconvenios4';
import Registroconvenios5 from '../Page/Registroconvenios5';
import Registroconvenios6 from '../Page/Registroconvenios6';
import Agendasychat from '../Page/Agendasychat';
import Agendasychat2 from '../Page/Agendasychat2';
import Agendasychat3 from '../Page/Agendasychat3';
import Agendasychat4 from '../Page/Agendasychat4';
import Agendasychat5 from '../Page/Agendasychat5';
import Agendasychat6 from '../Page/Agendasychat6';
import Agendasychat7 from '../Page/Agendasychat7';
import Agendasychat8 from '../Page/Agendasychat8';
import Agendasychat9 from '../Page/Agendasychat9';
import Agendasychat10 from '../Page/Agendasychat10';
import Visualizaractivos from '../Page/Visualizaractivos';
import Visualizaractivos2 from '../Page/Visualizaractivos2';
import Visualizaractivos3 from '../Page/Visualizaractivos3';
import Visualizaractivos4 from '../Page/Visualizaractivos4';
import Visualizaractivos5 from '../Page/Visualizaractivos5';
import Visualizaractivos6 from '../Page/Visualizaractivos6';
import Visualizaractivos7 from '../Page/Visualizaractivos7';
import Seguimientocultivos from '../Page/Seguimientocultivos';
import Seguimientocultivos2 from '../Page/Seguimientocultivos2';
import Seguimientocultivos3 from '../Page/Seguimientocultivos3';
import Seguimientocultivos4 from '../Page/Seguimientocultivos4';
import Seguimientocultivos5 from '../Page/Seguimientocultivos5';
import Seguimientocultivos6 from '../Page/Seguimientocultivos6';
import Seguimientocultivos7 from '../Page/Seguimientocultivos7';
import Seguimientocultivos8 from '../Page/Seguimientocultivos8';
import Seguimientocultivos9 from '../Page/Seguimientocultivos9';
import ofertacosechas from '../Page/ofertacosechas';
import ofertacosechas2 from '../Page/ofertacosechas2';
import ofertacosechas3 from '../Page/ofertacosechas3';
import ofertacosechas4 from '../Page/ofertacosechas4';
import ofertacosechas5 from '../Page/ofertacosechas5';
import ofertacosechas6 from '../Page/ofertacosechas6';
import ofertacosechas7 from '../Page/ofertacosechas7';
import ofertacosechas8 from '../Page/ofertacosechas8';
import ofertacosechas9 from '../Page/ofertacosechas9';
import Murointeraccion from '../Page/Murointeraccion';
import Murointeraccion2 from '../Page/Murointeraccion2';
import Murointeraccion3 from '../Page/Murointeraccion3';
import Murointeraccion4 from '../Page/Murointeraccion4';
import Murointeraccion5 from '../Page/Murointeraccion5';
import Murointeraccion6 from '../Page/Murointeraccion6';
import Murointeraccion7 from '../Page/Murointeraccion7';

import '../Styles/index.css';

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/Inicio" component={Inicio} />
            <Route exact path="/Inicio/Editarperfil" component={Editarperfil} />
            <Route exact path="/Inicio/Editarperfil2" component={Editarperfil2} />
            <Route exact path="/Inicio/Editarperfil3" component={Editarperfil3} />  
            <Route exact path="/Inicio/Editarperfil4" component={Editarperfil4} />
            <Route exact path="/Inicio/Editarperfil5" component={Editarperfil5} />
            <Route exact path="/Inicio/Editarperfil6" component={Editarperfil6} />
            <Route exact path="/Inicio/Agregarusuario" component={Agregarusuario} />
            <Route exact path="/Inicio/Agregarusuario2" component={Agregarusuario2} />
            <Route exact path="/Inicio/Agregarusuario3" component={Agregarusuario3} />
            <Route exact path="/Inicio/Agregarusuario4" component={Agregarusuario4} />
            <Route exact path="/Inicio/Agregarusuario5" component={Agregarusuario5} />
            <Route exact path="/Inicio/Agregarusuario6" component={Agregarusuario6} />
            <Route exact path="/Inicio/Agregarusuario7" component={Agregarusuario7} />
            <Route exact path="/Inicio/Agregarusuario8" component={Agregarusuario8} />
            <Route exact path="/Inicio/Afiliacionorganizaciones" component={Afiliacionorganizaciones} />
            <Route exact path="/Inicio/Afiliacionorganizaciones2" component={Afiliacionorganizaciones2} />
            <Route exact path="/Inicio/Afiliacionorganizaciones3" component={Afiliacionorganizaciones3} />
            <Route exact path="/Inicio/Afiliacionorganizaciones4" component={Afiliacionorganizaciones4} />
            <Route exact path="/Inicio/Afiliacionorganizaciones5" component={Afiliacionorganizaciones5} />
            <Route exact path="/Inicio/Registroconvenios" component={Registroconvenios} />
            <Route exact path="/Inicio/Registroconvenios2" component={Registroconvenios2} />
            <Route exact path="/Inicio/Registroconvenios3" component={Registroconvenios3} />
            <Route exact path="/Inicio/Registroconvenios4" component={Registroconvenios4} />
            <Route exact path="/Inicio/Registroconvenios5" component={Registroconvenios5} />
            <Route exact path="/Inicio/Registroconvenios6" component={Registroconvenios6} />
            <Route exact path="/Inicio/Agendasychat" component={Agendasychat} />
            <Route exact path="/Inicio/Agendasychat2" component={Agendasychat2} />
            <Route exact path="/Inicio/Agendasychat3" component={Agendasychat3} />
            <Route exact path="/Inicio/Agendasychat4" component={Agendasychat4} />
            <Route exact path="/Inicio/Agendasychat5" component={Agendasychat5} />
            <Route exact path="/Inicio/Agendasychat6" component={Agendasychat6} />
            <Route exact path="/Inicio/Agendasychat7" component={Agendasychat7} />
            <Route exact path="/Inicio/Agendasychat8" component={Agendasychat8} />
            <Route exact path="/Inicio/Agendasychat9" component={Agendasychat9} />
            <Route exact path="/Inicio/Agendasychat10" component={Agendasychat10} />
            <Route exact path="/Inicio/Visualizaractivos" component={Visualizaractivos} />
            <Route exact path="/Inicio/Visualizaractivos2" component={Visualizaractivos2} />
            <Route exact path="/Inicio/Visualizaractivos3" component={Visualizaractivos3} />
            <Route exact path="/Inicio/Visualizaractivos4" component={Visualizaractivos4} />
            <Route exact path="/Inicio/Visualizaractivos5" component={Visualizaractivos5} />
            <Route exact path="/Inicio/Visualizaractivos6" component={Visualizaractivos6} />
            <Route exact path="/Inicio/Visualizaractivos7" component={Visualizaractivos7} />
            <Route exact path="/Inicio/Seguimientocultivos" component={Seguimientocultivos} />
            <Route exact path="/Inicio/Seguimientocultivos2" component={Seguimientocultivos2} />
            <Route exact path="/Inicio/Seguimientocultivos3" component={Seguimientocultivos3} />
            <Route exact path="/Inicio/Seguimientocultivos4" component={Seguimientocultivos4} />
            <Route exact path="/Inicio/Seguimientocultivos5" component={Seguimientocultivos5} />
            <Route exact path="/Inicio/Seguimientocultivos6" component={Seguimientocultivos6} />
            <Route exact path="/Inicio/Seguimientocultivos7" component={Seguimientocultivos7} />
            <Route exact path="/Inicio/Seguimientocultivos8" component={Seguimientocultivos8} />
            <Route exact path="/Inicio/Seguimientocultivos9" component={Seguimientocultivos9} />
            <Route exact path="/Inicio/ofertacosechas" component={ofertacosechas} />
            <Route exact path="/Inicio/ofertacosechas2" component={ofertacosechas2} />
            <Route exact path="/Inicio/ofertacosechas3" component={ofertacosechas3} />
            <Route exact path="/Inicio/ofertacosechas4" component={ofertacosechas4} />
            <Route exact path="/Inicio/ofertacosechas5" component={ofertacosechas5} />
            <Route exact path="/Inicio/ofertacosechas6" component={ofertacosechas6} />
            <Route exact path="/Inicio/ofertacosechas7" component={ofertacosechas7} />
            <Route exact path="/Inicio/ofertacosechas8" component={ofertacosechas8} />
            <Route exact path="/Inicio/ofertacosechas9" component={ofertacosechas9} />
            <Route exact path="/Inicio/Murointeraccion" component={Murointeraccion} />
            <Route exact path="/Inicio/Murointeraccion2" component={Murointeraccion2} />
            <Route exact path="/Inicio/Murointeraccion3" component={Murointeraccion3} />
            <Route exact path="/Inicio/Murointeraccion4" component={Murointeraccion4} />
            <Route exact path="/Inicio/Murointeraccion5" component={Murointeraccion5} />
            <Route exact path="/Inicio/Murointeraccion6" component={Murointeraccion6} />
            <Route exact path="/Inicio/Murointeraccion7" component={Murointeraccion7} />
        </Switch>
    </BrowserRouter>
)


export default Routes

import React from 'react';
import { Row, Col, Table } from 'reactstrap';
import '../Styles/index.css';

const Editarperfil = (props) => {
  return (
      <React.Fragment>
<Row>
      <Col sm="12">
      <center>
          <div className="Tab">   
          <div className="final">
          <img src={require('../Imagenes/instructivofin.png')} className="img8"/>
      <a href="/Inicio">
          <div className="btn1">
          <img src={require('../Imagenes/icon12.png')} className="img7"/>
          <p className="cuatro">ir al menu</p>
          </div>
      </a>
      <a href="/Inicio/Agregarusuario">
          <br/>
          <div className="btn2">
          <img src={require('../Imagenes/icon13.png')} className="img7"/>
          <p className="cuatro">volver a ver</p>
          </div>
      </a>
          
          </div>
          <div className="foot">
            <a href="/Inicio/Agregarusuario7"><img src={require('../Imagenes/foot3.png')} className="img5"/></a>      
        </div>
          </div>
    
          </center>
      </Col>
    </Row>
      </React.Fragment>
      
  );
};

export default Editarperfil;
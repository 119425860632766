import React from 'react';
import { Row, Col, Table } from 'reactstrap';
import '../Styles/index.css';

const Inicio = (props) => {
  return (
      <React.Fragment>

<Row>
      <Col sm="12">
      <center>
          <div className="Tab">   
          <img src={require('../Imagenes/letrero.png')} className="img8"/>
      <Table className="Tab1">
      <tbody>
        <tr >
          <td>
            <a href="/Inicio/Editarperfil"><img src={require('../Imagenes/icon1.png')} className="img1"/><p className="dos">Editar Perfil</p><img src={require('../Imagenes/icon10.png')} className="img3"/></a>
          </td>
        </tr>
        <tr>
          <td>
          <a href="/Inicio/Agregarusuario"><img src={require('../Imagenes/icon2.png')} className="img2"/><p className="dos">Creacion de usuarios</p><img src={require('../Imagenes/icon10.png')} className="img3"/></a>
          </td>
        </tr>
        <tr>
          <td>
            <a href="/Inicio/Afiliacionorganizaciones"><img src={require('../Imagenes/icon3.png')} className="img2"/><p className="dos">Afiliacion a Organizaciones</p><img src={require('../Imagenes/icon10.png')} className="img3"/></a>
          </td>
        </tr>
        <tr>
          <td>
            <a href="/Inicio/Registroconvenios"><img src={require('../Imagenes/icon4.png')} className="img2"/><p className="dos">Registro a convenios</p><img src={require('../Imagenes/icon10.png')} className="img3"/></a> 
          </td>
        </tr>
        <tr>
          <td>
            <a href="/Inicio/Agendasychat"><img src={require('../Imagenes/icon5.png')} className="img2"/><p className="dos">Agendas y Chat</p><img src={require('../Imagenes/icon10.png')} className="img3"/></a> 
            </td>
        </tr>
        <tr>
          <td>
            <a href="/Inicio/Visualizaractivos"> <img src={require('../Imagenes/icon6.png')} className="img2"/><p className="dos">Visualizar Activos</p><img src={require('../Imagenes/icon10.png')} className="img3"/></a> 
            </td>
        </tr>
        <tr>
            <td>
            <a href="/Inicio/Seguimientocultivos"><img src={require('../Imagenes/icon7.png')} className="img2"/><p className="dos">Seguimiento de cultivos</p><img src={require('../Imagenes/icon10.png')} className="img3"/></a>           
            </td>
        </tr>
        <tr>
          <td>
            <a href="/Inicio/ofertacosechas"> <img src={require('../Imagenes/icon8.png')} className="img2"/><p className="dos">Oferta de cosechas</p><img src={require('../Imagenes/icon10.png')} className="img3"/></a>   
          </td>
        </tr>
        <tr>
          <td>
            <a href="/Inicio/Murointeraccion"><img src={require('../Imagenes/icon9.png')} className="img2"/><p className="dos">Muro de interaccion</p><img src={require('../Imagenes/icon10.png')} className="img3"/></a>
          </td>
        </tr>
      </tbody>
      </Table>
          </div>
          </center>
      </Col>
    </Row>

   
      </React.Fragment>
      
  );
};

export default Inicio;
import React from 'react';
import { Row, Col } from 'reactstrap';
import '../Styles/index.css';

const Editarperfil = (props) => {
  return (
      <React.Fragment>

<Row>
      <Col sm="12">
      <center>
          <div className="Tab">   
          <img src={require('../Imagenes/instructivova3.png')} className="img8"/>
          <div className="foot">
          <a href="/Inicio/Visualizaractivos2"><img src={require('../Imagenes/foot3.png')} className="img5"/></a>
            <a href="/Inicio"><img src={require('../Imagenes/foot1.png')}/></a>
            <a href="/Inicio/Visualizaractivos4"><img src={require('../Imagenes/foot2.png')} className="img4"/></a>
            </div>
          </div>
    
          </center>
      </Col>
    </Row>
      </React.Fragment>
      
  );
};

export default Editarperfil;